
var ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
  };
  
  ready(() => {
    var hamburger = document.querySelector(".hamburger");
    var menu = document.querySelector(".responsive-menu");
    hamburger.addEventListener("click", (e) => {
      if (!hamburger.classList.contains("is-active")) {
        if (typeof window.scroll === "function") { 
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        } else {
          var body = document.body; // For Safari
          var html = document.documentElement; // Chrome, Firefox, IE and Opera 
          body.scrollTop = 0; 
          html.scrollTop = 0;
        }
        hamburger.classList.add("is-active");
        menu.classList.remove("none");
        menu.classList.add("block");
      } else {
        hamburger.classList.remove("is-active");
        menu.classList.remove("block");
        menu.classList.add("none");
      }
    });
  });
  